<template>

  <b-row>
    <b-col
      cols="12"
      md="12"
    >
      <b-card title="O'yinlar">
            <b-tabs>
              <b-tab active>
                <template #title>
                  <feather-icon icon="HomeIcon" />
                  <span>O'ZBEK TILIDA</span>
                </template>
                <b-form class="mt-2">
                  <b-row>
                    <b-col md="6">

                      <b-form-group
                          label="Sarlavha Uz"
                          label-for="blog-edit-title"
                          class="mb-2"

                      >
                        <b-form-input
                            id="blog-edit-title"
                            v-model="gameForm.titleUz"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label="O'yin turi"
                          label-for="Kategoriya"
                          class="mb-2"
                      >
                        <v-select
                            v-model="gameForm.type"
                            :options="adviceCategoryTypes"
                            label="text"
                            :reduce="option => option.value"
                            placeholder="O'yin turini tanlang"

                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label="Yosh kategoriyasi"
                          label-for="Kategoriya"
                          class="mb-2"
                      >
                        <v-select
                            v-model="gameForm.ageCategoryId"
                            :options="ageCategoryTypes"
                            label="text"
                            :reduce="option => option.id"
                            placeholder="Yosh kategoriyasini tanlang"

                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label="Holati"
                          label-for="blog-edit-category"
                          class="mb-2"
                      >
                        <v-select
                            v-model="gameForm.isActive"
                            :options="isActiveTypes"
                            label="text"
                            :reduce="option => option.id"
                            placeholder="Tanlang"

                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6" class="mb-2">
                        <b-form-checkbox
                            v-model="gameForm.forToday"
                            unchecked-value="0"
                            value="1"
                        >
                          Kun o'yini
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                          label="Kontent UZ"

                          label-for="blog-content"
                          class="mb-2"
                      >
                        <quill-editor
                            p
                            style="height: 300px;"
                            id="blog-content"
                            placeholder="Ma'lumotni kiriting"
                            v-model="gameForm.contentUz"
                            :options="snowOption"
                        />

                      </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        class="mb-2 mt-4"
                    >
                      <div class="border rounded p-2">
                        <h4 class="mb-1" mt-1>
                          O'yin uchun tasvir
                        </h4>

                        <b-media
                            no-body
                            vertical-align="center"
                            class="flex-column flex-md-row"
                        >
                          <b-media-aside>

                            <el-upload
                                action="https://bolajon.mtsh.uz/api/file-storages/create"
                                list-type="picture-card"
                                :on-success="handlePictureSuccess"
                                :on-preview="handlePictureCardPreview"
                                name="main-document"
                                :on-remove="handleRemove">
                              <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                              <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                          </b-media-aside>
                          <b-media-body>
                            <small class="text-muted">Talab qilinadigan  tasvir hajmi max 1 mb..</small>
                          </b-media-body>
                        </b-media>
                      </div>
                    </b-col>
                    <b-col
                        cols="12"
                        class="mt-50"
                    >
                      <b-button
                          @click="cancledGameForm()"
                          class="mr-2"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                      >
                        Bekor qilish
                      </b-button>
                      <b-button
                          @click="submitGameForm()"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mr-1"
                      >
                        Saqlash
                      </b-button>

                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <b-tab >
                <template #title>
                  <feather-icon icon="ToolIcon" />
                  <span>RUS TILIDA</span>
                </template>

                <b-form class="mt-2">
                  <b-row>
                    <b-col md="6">

                      <b-form-group
                          label="Sarlavha Uz"
                          label-for="blog-edit-title"
                          class="mb-2"

                      >
                        <b-form-input
                            id="blog-edit-title"
                            v-model="gameForm.titleRu"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label="Maslahat turi"
                          label-for="Kategoriya"
                          class="mb-2"
                      >
                        <v-select
                            v-model="gameForm.type"
                            :options="adviceCategoryTypes"
                            label="text"
                            :reduce="option => option.value"
                            placeholder="Maslahat turini tanlang"

                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label="Yosh kategoriyasi"
                          label-for="Kategoriya"
                          class="mb-2"
                      >
                        <v-select
                            v-model="gameForm.ageCategoryId"
                            :options="ageCategoryTypes"
                            label="text"
                            :reduce="option => option.id"
                            placeholder="Yosh kategoriyasini tanlang"

                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label="Holati"
                          label-for="blog-edit-category"
                          class="mb-2"
                      >
                        <v-select
                            v-model="gameForm.isActive"
                            :options="isActiveTypes"
                            label="text"
                            :reduce="option => option.id"
                            placeholder="Tanlang"

                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6" class="mb-2">
                      <b-form-checkbox
                          v-model="gameForm.forToday"
                          unchecked-value="0"
                          value="1"
                      >
                        Kun o'yini
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                          label="Kontent UZ"

                          label-for="blog-content"
                          class="mb-2"
                      >
                        <quill-editor
                            p
                            style="height: 300px;"
                            id="blog-content"
                            placeholder="Ma'lumotni kiriting"
                            v-model="gameForm.contentRu"
                            :options="snowOption"
                        />

                      </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        class="mb-2 mt-4"
                    >



                      <div class="border rounded p-2">
                        <h4 class="mb-1" mt-1>

                        </h4>

                        <b-media
                            no-body
                            vertical-align="center"
                            class="flex-column flex-md-row"
                        >
                          <b-media-aside>

                          </b-media-aside>
                          <b-media-body>



                          </b-media-body>
                        </b-media>
                      </div>
                    </b-col>
                    <b-col
                        cols="12"
                        class="mt-50"
                    >
                      <b-button
                          @click="cancledGameForm()"
                          class="mr-2"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                      >
                        Bekor qilish
                      </b-button>
                      <b-button
                          @click="submitGameForm()"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mr-1"
                      >
                        Saqlash
                      </b-button>

                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon icon="UserIcon" />
                  <span>INGILIZ TILIDA</span>
                </template>

                <b-form class="mt-2">
                  <b-row>
                    <b-col md="6">

                      <b-form-group
                          label="Sarlavha Uz"
                          label-for="blog-edit-title"
                          class="mb-2"

                      >
                        <b-form-input
                            id="blog-edit-title"
                            v-model="gameForm.titleEn"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label="Maslahat turi"
                          label-for="Kategoriya"
                          class="mb-2"
                      >
                        <v-select
                            v-model="gameForm.type"
                            :options="adviceCategoryTypes"
                            label="text"
                            :reduce="option => option.value"
                            placeholder="Maslahat turini tanlang"

                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label="Yosh kategoriyasi"
                          label-for="Kategoriya"
                          class="mb-2"
                      >
                        <v-select
                            v-model="gameForm.ageCategoryId"
                            :options="ageCategoryTypes"
                            label="text"
                            :reduce="option => option.id"
                            placeholder="Yosh kategoriyasini tanlang"

                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                          label="Holati"
                          label-for="blog-edit-category"
                          class="mb-2"
                      >
                        <v-select
                            v-model="gameForm.isActive"
                            :options="isActiveTypes"
                            label="text"
                            :reduce="option => option.id"
                            placeholder="Tanlang"

                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6" class="mb-2">
                      <b-form-checkbox
                          v-model="gameForm.forToday"
                          unchecked-value="0"
                          value="1"
                      >
                        Kun o'yini
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                          label="Kontent UZ"

                          label-for="blog-content"
                          class="mb-2"
                      >
                        <quill-editor
                            p
                            style="height: 300px;"
                            id="blog-content"
                            placeholder="Ma'lumotni kiriting"
                            v-model="gameForm.contentEn"
                            :options="snowOption"
                        />

                      </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        class="mb-2 mt-4"
                    >



                      <div class="border rounded p-2">
                        <h4 class="mb-1" mt-1>

                        </h4>

                        <b-media
                            no-body
                            vertical-align="center"
                            class="flex-column flex-md-row"
                        >
                          <b-media-aside>


                          </b-media-aside>
                          <b-media-body>



                          </b-media-body>
                        </b-media>
                      </div>
                    </b-col>
                    <b-col
                        cols="12"
                        class="mt-50"
                    >
                      <b-button
                          @click="cancledGameForm()"
                          class="mr-2"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                      >
                        Bekor qilish
                      </b-button>
                      <b-button
                          @click="submitGameForm()"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mr-1"
                      >
                        Saqlash
                      </b-button>

                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
            </b-tabs>

            <template #code>
              {{ codeIcon }}
            </template>
          <hr>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import {
  BButton,
  BModal,
  BTable,
  BFormSelect,
  BPagination,
  VBModal,
  BFormCheckbox,
  BRow,
  BCol,
  BForm,
  BCard,
  BFormInput,
  BFormGroup, BMediaBody, BMediaAside, BImg, BMedia, BFormFile, BLink, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

export default {
  components: {
    quillEditor,
    BAvatar,
    BLink,
    BFormFile,
    BMedia,
    BCardCode,
    BTabs,
    BTab,
    BImg,
    BMediaAside,
    BCardText,
    BMediaBody,
    ValidationProvider,
    BRow,
    BCol,
    vSelect,
    BTable,
    BFormSelect,
    BPagination,
    BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      blogEdit: {},
      blogFile: null,
      categoryOption: ['Fashion', 'Food', 'Gaming', 'Quote', 'Video'],
      statusOption: ['Published', 'Pending'],
      snowOption: {
        theme: 'snow',
        placeholder: 'Ma‘lumotni kiriting',

      },
      editedUserId: null,

      ageCategoryTypes: [
        { id: 1, text: '1 oylik' },
        { id: 2, text: '2 oylik' },
        { id: 3, text: '3-4 oylik' },
        { id: 4, text: '5-6 oylik' },
        { id: 5, text: '7-9 oylik' },
        { id: 6, text: '10-12 oylik' },
        { id: 7, text: '13-18 oylik' },
        { id: 8, text: '19-24 oylik' },
      ],
      isActiveTypes: [
        { id: 1, text: 'Faol' },
        { id: 2, text: 'Nofaol' },
      ],
      category: {
        type: '',
      },
      adviceCategoryTypes: [
        { value: 'IJTIMOIY', text: 'Ijtimoiy-emotsional' },
        { value: 'KOGNITIV', text: 'Kognitiv' },
        { value: 'TIL', text: 'Til va aloqa' },
        { value: 'MOTORIKA', text: 'Motorika' },
      ],
      gameForm: {
        "id": null,
        "titleUz": null,
        "titleEn": null,
        "titleRu": null,
        "contentUz": null,
        "contentEn": null,
        "contentRu": null,
        "ageCategoryId": null,
        "type": '',
        "forToday": null,
        "isActive": null,
        "isDeleted": null,
        "hashId": null
      },
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      users: [],
      items: [],
      tableKey:0,
    }
  },
watch: {

},
  created() {
    this.editedUserId = this.$route.params.id;
    if (this.editedUserId) {
      this.fetchGameData(this.editedUserId);
    }
    // this.$http.get('/blog/list/data/edit').then(res => { this.blogEdit = res.data })
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePictureSuccess(res, file) {
      this.gameForm.hashId = res.hashId;
    },
    openEditCategory(categoryId) {
      const categoryToEdit = this.items.find(category => category.id === categoryId)
      this.category = { ...categoryToEdit }
      this.$nextTick(() => {
        this.$refs.startPointInput.focus();
      });
    },
    fetchGameData(id) {
      this.$http.get(`/game/find-by-id/${id}`)
          .then(response => {
            const gameData = response.data;
            this.gameForm = {
              id: gameData.id,
              titleUz: gameData.titleUz,
              titleRu: gameData.titleRu,
              titleEn: gameData.titleEn,
              contentUz: gameData.contentUz,
              contentRu: gameData.contentRu,
              contentEn: gameData.contentEn,
              type: gameData.type,
              hashId: gameData.hashId,
              forToday: gameData.forToday,
              ageCategoryId: gameData.ageCategoryId,
              isActive: gameData.isActive,
            };
          })
          .catch(error => {
            console.error('Axios error:', error);
          });
    },
    cancledGameForm() {
      this.gameForm = {};
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'O‘yinlar',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
          text: "Ma'lumot saqlash bekor qilindi !",
        },
      })
    },
    submitGameForm() {
      this.$http
        .post(`/game/save`, this.gameForm)
        .then(() => {
          this.gameForm={};
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'O‘yinlar',
              icon: 'AlertTriangleIcon',
              variant: 'success',
              text: "Ma'lumot muvaffaqiyatli saqlandi !",
            },
          })
          setTimeout(() => {

            this.$router.push({
              name: 'game-blog',
            })
          }, 400);


          this.$bvModal.hide('modal-login')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Xatolik yuz berdi',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: "Ma'lumotni saqlashda xatolik yuz berdi. Qaytadan urinib ko'ring",
            },
          })
        })
    },
    handleMouseOver(event) {
      event.target.classList.add('hovered-icon');
    },
    handleMouseOut(event) {
      event.target.classList.remove('hovered-icon');
    },
  },


}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
.hovered-icon{
  transition: transform 0.25s ease;
  cursor: pointer;
  font-size: 12px;
  color: #7367f0;

}

</style>
